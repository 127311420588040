<template>
  <el-container>
    <el-aside>
      <SiderMenu />
    </el-aside>
    <el-container class="mars-ape-layout">
      <el-header>
        <Header ref='refHeader' :helpShow.sync="helpShow"/>
      </el-header>
      <el-main :class='`${$route.name}_main`'>
<!--        <HelpDocs></HelpDocs>-->
        <div style='display: flex; flex-direction: column' :class='`${$route.name}_main-inner`'>
          <div v-if='isRootRoutePath'>
            <div class='mod-index'>
              <HomeIndex></HomeIndex>
            </div>
          </div>
          <router-view :class='`${$route.name}_main-inner__content`' />
        </div>
      </el-main>
<!--      <el-footer>-->
<!--        <Footer />-->
<!--      </el-footer>-->
    </el-container>
  </el-container>
</template>
<script>
import SiderMenu from './SiderMenu'
import Header from './Header'
import Footer from './Footer'
import HomeIndex from '@/pages/home/HomeIndex'
import {mapGetters} from 'vuex'
import HelpDocs from './HelpDocs.vue'

export default {
  name: 'BasicLayout',
  components: {
    HomeIndex,
    SiderMenu,
    Header,
    Footer,
    HelpDocs
  },
  data() {
    return {
      helpShow: false
    }
  },

  computed: {
    ...mapGetters(['userPermissions']),

    isRootRoutePath: function() {
      return this.$route.path === '/'
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.defaultRoute()
    // }, 500)

  },
  methods: {
    defaultRoute() {
      // let routePath = '/dashboard'
      // console.log(this.userPermissions)
      // 若有仪表盘权限，优先进入仪表盘
      // if (this.$route.path === '/' && this.userPermissions.indexOf('manage_dashboard_menu') !== -1)
      //   this.$router.push({ path: '/dashboard' })
      //
      // if (this.$route.path === '/' && this.userPermissions.indexOf('dashboard_trend') !== -1)
      //   this.$router.push({ path: '/trend_data' })
    }
  },
  created() {
    // console.log('basic layout')
    this.$globalWs.connection()
  },
  activated() {
    // console.log('activated')
    this.$globalWs.connection()
  },
  deactivated() {
    // console.log('deactivated')
    this.$globalWs.close()
  },

  beforeDestroy() {
    // console.log('beforeDestroy')
    this.$globalWs.close()
  },
  destroyed() {
    // console.log('destroyed')
  }
}
</script>
<style lang='stylus'>
.mod-index
  border-radius 5px
  background #fff
  padding 20px
  min-height 800px
  line-height 1.5

  p
    margin 0

  h3
    margin-block-start 0
    margin-block-end 0.5em

  .xm-gt img
    height 240px
    margin-left -16px

  .jx-zz img
    height 400px
    margin-top 24px
    margin-right 24px

.el-container
  background #f0f2f5

.el-aside
  height 100%
  max-height 100vh
  overflow-y auto
  box-shadow 2px 0 6px rgba(0, 21, 41, .35)
  position relative
  width auto !important
  z-index 10
  transitionall .3s
  background #001529

.el-header
  height 66px !important
  padding 0
  width 100%
  //border 1px solid red
  box-shadow: 0 2px 8px #f2f3f5 !important
  //margin-bottom 0px


.el-main
  padding 0
  //margin 6px 4px
  overflow inherit
  flexnone !important


//min-height 720px

.main-page-content
  //border-radius 5px
  //background #ffffff

//padding 8px 10px 0 10px

.el-footer
  height auto !important
</style>
<style lang='scss' scoped>
.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  //width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}
</style>
<style lang='scss'>
.main-page-content {


  > *:not(.secondary-nav,button,.ape-drawer,.el-drawer) {
    background-color: #FFFFFF;
    padding: 8px 10px 0 10px;
  }
}
</style>