<template>
  <div style="width: 100%">
    <div class="nav" id="nav">
      <el-menu :active-text-color="`#ff3176`" :background-color="`#fff`"
               style="max-height: 65px" :default-active="routePath"
               :text-color="`#000`"  mode="horizontal">
        <template v-for="(v, k) in navMenus">
          <template v-if="v.children && v.children.length">
            <el-submenu :index="v['url'].toString()" :key="k"
                        v-if="!(v['permission_name'] ? userPermissions.indexOf(v['permission_name']) === -1 : false)">
              <template slot="title">
                <svg-icon :fill="fillColor" :class="`iconfont`" style="margin-right: 10px;display: inline-block"
                          :icon-class="v['icon']"></svg-icon>
                <span>{{ calcMenuTitle(v) }}</span>
              </template>
              <template v-for="(item, indx) in v.children">
                <el-menu-item :index="item['url'].toString()" :key="k + '-' + indx" style=""
                              v-if="!(item['permission_name'] ? userPermissions.indexOf(item['permission_name']) === -1 : false)"   @click='goToPage(item.url,item)'>
                  <!--                  <i :class="`iconfont ${item['icon']}`" ></i>-->
                  <svg-icon :fill="fillColor" :class="`iconfont`"  style="display: inline-block" :icon-class="item['icon']"></svg-icon>

                  <span >{{ item['display_name'] }}</span>
                </el-menu-item>
              </template>
            </el-submenu>
          </template>
          <template v-else-if="!(v['permission_name'] ? userPermissions.indexOf(v['permission_name']) === -1 : false)">
            <el-menu-item :key="k + 'index'" :index="v['url'].toString()" class="nav_item" @click='goToPage(v.url,v)'>
              <!--              <i :class="`iconfont ${v['icon']}`"></i>-->
              <svg-icon :fill="fillColor" :class="`iconfont`" style="display: inline-block"
                        :icon-class="v['icon']"></svg-icon>

              <span slot="title" >{{ calcMenuTitle(v) }}</span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>

    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'TopMenu',
  computed: {
    ...mapGetters(['routePath', 'userPermissions', 'isCollapse'])
  },
  data() {
    return {
      fillColor: 'currentColor',
      // navMenus: [
      //   {
      //     icon: 'icon-shouye',
      //     display_name: '首页',
      //     url: '/home',
      //     permission_name: 'home'
      //   },
      //   {
      //     icon: 'icon-dashboard',
      //     display_name: '仪表盘',
      //     url: '/dashboard',
      //     permission_name: 'manage_dashboard_menu'
      //   },
      //   {
      //     icon: 'icon-zhangfen',
      //     display_name: '涨粉',
      //     url: '/trend_data',
      //     permission_name: 'dashboard_trend'
      //   },
      //   {
      //     icon: 'icon-dianshang',
      //     display_name: '电商',
      //     url: '/business/live_data',
      //     permission_name: 'live_data_list',
      //     children: [
      //       {
      //         icon: 'icon-zhibo',
      //         display_name: '直播数据',
      //         url: '/business/live_data',
      //         permission_name: 'live_data_list'
      //       },
      //       {
      //         icon: 'icon-dianpu',
      //         display_name: '店铺数据',
      //         url: '/business/shop_data',
      //         permission_name: 'shop_data_list'
      //       },
      //       {
      //         icon: 'icon-dianpu',
      //         display_name: '每日汇总',
      //         url: '/business/day_summary',
      //         permission_name: 'bs_day_summary'
      //       }
      //     ],
      //   },
      //   {
      //     icon: 'icon-yingshoushujlur',
      //     display_name: '营收',
      //     url: '/revenue_menu/revenue_statistics',
      //     permission_name: 'dashboard_revenue',
      //     children: [
      //       {
      //         icon: 'icon-yingshoushujlur',
      //         display_name: '营收',
      //         url: '/revenue_menu/revenue_statistics',
      //         permission_name: 'dashboard_revenue'
      //       },
      //       {
      //         icon: 'icon-zhangfen',
      //         display_name: '订单排行',
      //         url: '/revenue_menu/order_statistics',
      //         permission_name: 'order_statistics'
      //       }
      //     ],
      //   },
      //   {
      //     icon: 'el-icon-office-building',
      //     display_name: '团队',
      //     url: '/team',
      //     permission_name: 'team_detail'
      //   },
      //   {
      //     icon: 'icon-kanli',
      //     display_name: '刊例',
      //     url: '/plc',
      //     permission_name: 'plc_list'
      //   },
      //   {
      //     icon: 'el-icon-s-promotion',
      //     display_name: '询单',
      //     url: '/inquiries',
      //     permission_name: 'inquiry_menu',
      //     children: [
      //       {
      //         icon: 'el-icon-guide',
      //         display_name: '全部询单',
      //         url: '/inquiries/list',
      //         permission_name: 'inquiry_all_projects'
      //       },
      //       {
      //         icon: 'el-icon-sunset',
      //         display_name: '我的询单',
      //         url: '/inquiries/myInquir',
      //         permission_name: 'inquiry_owner_projects'
      //       },
      //       {
      //         icon: 'el-icon-postcard',
      //         display_name: '报名列表',
      //         url: '/inquiries/applylist',
      //         permission_name: 'inquiry_apply_list'
      //       }
      //     ],
      //   },
      //   {
      //     icon: 'el-icon-date',
      //     display_name: '档期',
      //     url: '/s_menus/artist_schedule',
      //     permission_name: 'schedule_list',
      //     children: [
      //       {
      //         icon: 'el-icon-date',
      //         display_name: '档期管理',
      //         url: '/s_menus/artist_schedule',
      //         permission_name: 'schedule_list'
      //       },
      //       {
      //         icon: 'el-icon-notebook-1',
      //         display_name: '商务管理',
      //         url: '/s_menus/business_manage',
      //         permission_name: 'business_manage_list'
      //       }
      //     ]
      //   },
      //   {
      //     icon: 'el-icon-document',
      //     display_name: '排期',
      //     url: '/lb_menu/lb_schedule',
      //     permission_name: 'lb_schedule_list',
      //     children: [
      //       {
      //         icon: 'el-icon-date',
      //         display_name: '直播排期',
      //         url: '/lb_menu/lb_schedule',
      //         permission_name: 'lb_schedule_list'
      //       },
      //       {
      //         icon: 'el-icon-shopping-cart-2',
      //         display_name: '产品库',
      //         url: '/lb_menu/product_library',
      //         permission_name: 'product_list'
      //       }
      //     ]
      //   },
      //   {
      //     icon: 'el-icon-reading',
      //     display_name: '数据管理',
      //     url: '/data',
      //     permission_name: 'dashboard_enter'
      //   },
      //   {
      //     icon: 'el-icon-reading',
      //     display_name: 'OA审批',
      //     url: '/h5-oa',
      //     permission_name: 'oa_list'
      //   },
      //   {
      //     icon: 'icon-jixiaoguanli',
      //     display_name: '绩效',
      //     url: '/performance/objectives',
      //     permission_name: 'performance_trend_page',
      //     children: [
      //       {
      //         icon: 'icon-jixiaoguanli',
      //         display_name: '绩效目标',
      //         url: '/performance/objectives',
      //         permission_name: 'performance_trend_page'
      //       },
      //       {
      //         icon: 'icon-jixiaojiangjin',
      //         display_name: '奖金分配',
      //         url: '/performance/bonus',
      //         permission_name: 'bonus_list'
      //       },
      //       {
      //         icon: 'icon-mingxinganli',
      //         display_name: '抖音案例',
      //         url: '/performance/case_record',
      //         permission_name: 'case_record_tiktok'
      //       },
      //       {
      //         icon: 'icon-jixiaoguanli',
      //         display_name: '总监绩效',
      //         url: '/performance/dept',
      //         permission_name: 'perf_dept_list'
      //       }
      //     ]
      //   },
      //   {
      //     icon: 'icon-qianyue',
      //     display_name: '签约',
      //     url: '/sign/info',
      //     permission_name: 'sign_info_page',
      //     children: [
      //       {
      //         icon: 'icon-qianyue',
      //         display_name: '签约信息',
      //         url: '/sign/info',
      //         permission_name: 'sign_info_page'
      //       },
      //       {
      //         icon: 'icon-luru',
      //         display_name: '签约录入',
      //         url: '/sign/create',
      //         permission_name: 'sign_enter_page'
      //       },
      //       {
      //         icon: 'icon-zhuan',
      //         display_name: '红人转组',
      //         url: '/sign/transfer_group',
      //         permission_name: 'sign_transfer_group'
      //       },
      //       {
      //         icon: 'icon-jieyue',
      //         display_name: '红人解约',
      //         url: '/sign/sign_fired',
      //         permission_name: 'sign_fired_page'
      //       }
      //     ]
      //   },
      //   {
      //     icon: 'icon-zhanghuxinxi3',
      //     display_name: '用户',
      //     url: '/user',
      //     permission_name: 'user_list'
      //   },
      //   {
      //     icon: 'el-icon-postcard',
      //     display_name: '公告',
      //     url: '/sys_notice',
      //     permission_name: 'sys_notice_list'
      //   },
      //   {
      //     icon: 'icon-hongren',
      //     display_name: '红人分成',
      //     url: '/artist_commission',
      //     permission_name: 'artist_divide_list'
      //   },
      //   {
      //     icon: 'el-icon-cold-drink',
      //     display_name: '红人资源库',
      //     url: '/kol/XhsResources',
      //     permission_name: 'kol_info_list'
      //   },
      //   {
      //     icon: 'el-icon-mobile',
      //     display_name: 'OA审批管理',
      //     url: '/oaManage',
      //     permission_name: 'oa_manage_list'
      //   },
      //   // {
      //   //   icon: 'el-icon-mobile',
      //   //   display_name: '即时通讯',
      //   //   url: '/ws_tool',
      //   //   permission_name: 'oa_manage_list'
      //   // }
      // ],
      //导航是否溢出
      isNavOverflow: true,
    }
  },
  props: {
    navMenus: {
      type: Array,
      required: true
    }
  },
  mounted() {
    window.onload = () => {
      this.handle()
    }

    //防抖
    function debounce(fn, wait) {
      var timeout = null
      return function() {
        if (timeout !== null) clearTimeout(timeout)
        timeout = setTimeout(fn, wait)
      }
    }
    window.addEventListener('resize', debounce(this.handle, 500))
  },
  watch: {
    isCollapse: {
      immediate: true,
      // deep:false,
      handler() {
        this.handle()
      }
    },
    isNavOverflow: {
      immediate: true,
      handler() {
        this.$emit('isMore', this.isNavOverflow)
      }
    }
  },
  methods: {
    handle() {
      let o = document.getElementById('nav')
      setTimeout(() => {
        if (o) {
          let h = o.offsetHeight //高度
          if (h > 65 || o.children.length * 100 >= o.offsetWidth) {
            this.isNavOverflow = true
          } else {
            this.isNavOverflow = false
          }
          // o.children.length * 100
        } else this.isNavOverflow = true
      }, 500)


    },
    //计算主菜单的名称
    calcMenuTitle(menuItem) {
      let title = ''
      //若是子菜单被选中，则线上子菜单名称
      if (menuItem.children && menuItem.children.length > 0) {
        const menu = menuItem.children.find((value) => value.url === this.routePath)
        title = menu ? menu.display_name : menuItem.display_name
      } else {
        title = menuItem.display_name ? menuItem.display_name : ''
      }

      return title
    },
    goToPage(routeName, menu) {
      // console.log(routeName)
      this.$utils.jumpPage(this, routeName, menu)
    }
  },
}
</script>

<style lang="stylus">
.mars-ape-sider:not(.mars-ape-sider-collapse) {
  /* flex 0 0 256px */
  width: 160px !important;
  transition: all 0.3s;
}

.mars-ape-sider-collapse, .el-menu--collapse {
  /* flex 0 0 80px */
  width: 0 !important;
  transition: all 0.3s;
}

/* 导航头部 */
.sider-logo {
  height: 64px;
  position: relative;
  line-height: 64px;
  padding-left: 24px;
  transition: all 0.3s;
  background-color: #001529;
  // background #000c17
  // background #002140
  overflow: hidden;
  max-height: 66px !important;

  img {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }

  h1 {
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    margin: 0 0 0 12px;
    font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-weight: 600;
  }
}

/* 导航内容 */
.sider-menu {
  min-height: 100vh;
  width: auto !important;
  transition: all 0.3s;
}

.el-menu {
  border-right: solid 0 !important;
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 0;
  background-color: #001529;
  font-size: 14px;
}

.sider-menu-sub {
  background: #000c17;
  box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.45);
}

.el-submenu__title, .el-submenu__title {
  color: #b8bec3;
}

.el-submenu__title {
  padding-left: 10px !important;
  padding-right: 10px !important;
  // margin-top 4px
  // margin-bottom 4px
  width: 100% !important;
  line-height: 40px;
  height: 40px;
}

.el-menu-item {
  height: 40px;
  line-height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 4px;
  margin-bottom: 8px;
  color: #b8bec3;

  a {
    color: #b8bec3;
    text-decoration: none;
    display: block;
  }
}

.el-menu-item:hover, .el-menu-item:focus {
  background-color: #000c17;
}

.el-menu-item.is-active {
  background-color: #000c17;
}

/* 折叠后子菜单效果 */
.el-menu--vertical {
  left: 84px !important;
}

.el-menu--popup {
  border-radius: 4px;
  min-width: 160px;
}
</style>
<style lang="scss" scoped>
.iconfont {
  vertical-align: middle;
  margin-right: 5px;
  width: 24px;
  text-align: center;
  font-size: 18px;
  color: #909399;
}

.nav {
  > * {
    overflow: hidden;
  }
}

</style>
